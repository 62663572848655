import React, { useState } from 'react'
import './index.scss'

export default function Support() {
  const [hide, setHide] = useState(false)
  return (
    <div>
     This pages is building, 
    </div>
  )
}
