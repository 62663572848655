export const data_default_day_off = [{
    location: '',
    startDate: '',
    endDate: '',
    coverWorker: '',
    manager: '',
    paytype: '',
    shiftday: '',
    reason: ''
}
]