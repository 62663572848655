
export const itemsPerPage = [
    {
        text: '10', value: 10
    },
    {
        text: '50', value: 50
    },
    {
        text: '100', value: 100
    },
    {
        text: '200', value: 200
    },
    {
        text: '500', value: 500
    }
]