export const userList_dkp = [
    {
        no: '001',
        name: 'My',
        dkp: '1',
        vacation: '0',
    },
    {
        no: '002',
        name: 'Huy',
        dkp: '2',
        vacation: '0',
    },
    {
        no: '003',
        name: 'Khanh',
        dkp: '3',
        vacation: '0',
    },
    {
        no: '004',
        name: 'David',
        dkp: '4',
        vacation: '0',
    },
]
