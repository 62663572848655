export const data_user_detail = [
    {
        no: '001',
        username: 'AbbyB',
        nickname: 'Abby Bui',
        email: 'abbybui@worldcraftlogistics.com',
        address: '60K98 Trần Nhật Duật, Vĩnh Hòa, Nha Trang',
        eeo: 'Asian',
        location: 'VN-NT',
        department: 'IT',
        position: 'Front-End Dev',
        gender: 'Male',
        confirm: '0207931831',
        contractType: 'Full Time',
        birtdDay: '28/11/1997',
        marital: 'single',
        startDay: '03/03/2023',
        status: 'Active',
        salary: '0.00',
        vac: '10',
        sick: '16',
        dkp: '5',
    },
];
export const data_user_detail_default = [{
    no: '',
    username: '',
    nickname: '',
    email: '',
    address: '',
    eeo: '',
    location: '',
    department: '',
    position: '',
    gender: '',
    confirm: '',
    contractType: '',
    birtdDay: '',
    marital: '',
    startDay: '',
    status: '',
    salary: '0.00',
    vac: '',
    sick: '',
    dkp: '',
}
]

